@use 'variables';

@import 'utilities/animations';
@import 'utilities/separators';

.box-shadow-top {
    box-shadow: 0 calc(-1 * variables.$box-shadow-offset) variables.$box-shadow-blur-radius variables.$box-shadow-color;
}

.box-shadow-bottom {
    box-shadow: 0 variables.$box-shadow-offset variables.$box-shadow-blur-radius variables.$box-shadow-color;
}

/* Size */
.w-fit-content {
    width: fit-content;
}

/* Border radius */
.border-r-10 {
    border-radius: 10px;
}

/* Background color */
.jn-bg-pop-blue {
    background-color: variables.$pop-blue;
}

.jn-bg-super-light-blue-t {
    background-color: variables.$super-light-blue-t;
}

/* Font color */
.jn-font-sl-blue {
    color: variables.$super-light-blue;
}

.jn-font-dark-gray {
    color: variables.$dark-gray;
}

.jn-font-github-logo-color {
    color: variables.$github-logo-color !important;
}

.jn-font-linkedin-blue {
    color: variables.$linkedin-blue !important;
}

.jn-font-pop-blue {
    color: variables.$pop-blue;
}

/* Font size */
.jn-font-xxl {
    font-size: 10rem;
}

.jn-font-xl {
    font-size: 5rem;
}

.jn-font-axl {
    font-size: 3.5rem;
}

.jn-font-l {
    font-size: 2.5rem;
}

.jn-font-m {
    font-size: 1.85rem;
}

/* Lists */
.jn-no-bullets {
    list-style-type: none;
} 

/* dividers */

.divider-right {
    position: relative;
    padding-right: 30px;
}

.divider-right::after {
    position: absolute;
    content: '';
    height: 70%;
    top: 15%;
    right: 0;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 1px;
}

/* Gutter Containers */

.gutter-container {
    display: flex;
    flex-wrap: wrap;
}

.flex-gap-xsm {
    --gap-width: 5px;
    gap: variables.$gap-width;
}

.flex-gap-md {
    --gap-width: 20px;
    gap: variables.$gap-width;
}

/********************************************************************
 * Breakpoints
 *******************************************************************/

/* sm */

@media only screen and (min-width: 576px) {
    .jn-font-xxl-sm {
        font-size: 10rem;
    }

    .jn-font-xl-sm {
        font-size: 5rem;
    }

    .jn-font-l-sm {
        font-size: 2.5rem;
    }

    .jn-font-m-sm {
        font-size: 1.85rem;
    }
}

/* md */
@media only screen and (min-width: 768px) {
    .jn-font-xxl-md {
        font-size: 10rem;
    }

    .jn-font-xl-md {
        font-size: 5rem;
    }

    .jn-font-l-md {
        font-size: 2.5rem;
    }

    .jn-font-m-md {
        font-size: 1.85rem;
    }
}

/* lg */
@media only screen and (min-width: 992px) {
    /* Font size */
    .jn-font-xxl-lg {
        font-size: 10rem;
    }

    .jn-font-xl-lg {
        font-size: 5rem;
    }

    .jn-font-l-lg {
        font-size: 2.5rem;
    }

    .jn-font-m-lg {
        font-size: 1.85rem;
    }
}

/* xl */
@media only screen and (min-width: 1200px) {
    .jn-font-xxl-xl {
        font-size: 10rem;
    }

    .jn-font-xl-xl {
        font-size: 5rem;
    }

    .jn-font-l-xl {
        font-size: 2.5rem;
    }

    .jn-font-m-xl {
        font-size: 1.85rem;
    }
}

/* xxl */
@media only screen and (min-width: 1400px) {
    .jn-font-xxl-xxl {
        font-size: 10rem;
    }

    .jn-font-xl-xxl {
        font-size: 5rem;
    }

    .jn-font-l-xxl {
        font-size: 2.5rem;
    }

    .jn-font-m-xxl {
        font-size: 1.85rem;
    }
}