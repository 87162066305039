@use 'variables';

body, #root {
    font-family: Arial, Helvetica, sans-serif;
    min-height: 100vh;
    background-color: variables.$bg-gray;
    color: variables.$text-color;
    overflow-x: hidden;
}

#root:not(h1, h2, h3, h4, h5) {
    font-size: 1.25rem;
}

main {
    min-height: calc(100vh - variables.$header-height);
    width: 100%;
}

main>.jn-container {
    max-width: 1000px;
    width: 100%;
}

/* Headings */

h1:not(.header h1) {
    margin-left: 30px;
}

h3 {
    margin-left: 20px;
}

/* Links */
a:link, a:visited {
    text-decoration: none;
}

a:not(a.btn), a:not(a.btn):visited {
    color: variables.$pop-blue;
}

/* media queries */
@media only screen and (max-width: 1800px) {
    main>.jn-container {
        max-width: 900px;
    }
}

@media only screen and (max-width: 1400px) {
    main>.jn-container {
        max-width: 800px;
    }
}

@media only screen and (max-width: 1200px) {
    main>.jn-container {
        max-width: 700px;
    }
}

@media only screen and (max-width: 1000px) {
    main>.jn-container {
        max-width: 550px;
    }
}

@media only screen and (max-width: 600px) {
    main>.jn-container {
        max-width: 350px;
    }
}

@media only screen and (max-width: 400px) {
    main>.jn-container {
        max-width: 300px;
    }
}