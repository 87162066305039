/**********************************************
 * Colors
 *********************************************/
$almost-black: #000f1b;
$gray: #666;
$dark-gray: #333;
$bg-gray: #272D33;
$orange: #FF9C00;
$light-blue: #8AC8FF;
$pop-blue: #0086FF;
$dark-blue: #005EB3;
$extra-light-blue: #60686e;
$super-light-blue: #abb7c0;
$super-light-blue-t: #abb7c040;
$box-shadow-color: #0000001a;

/* Text Colors */
$text-color: #FFFAFA;

/* Logo Colors */
$github-logo-color: white;
$linkedin-blue: #0077B5;

/************************************************
* Size & Spacing
***********************************************/
/* Box shadow sizing */
$box-shadow-offset: 4px;
$box-shadow-blur-radius: 6px;

/* Size & width */
$header-height: 85px;
$gap-width: 10px;