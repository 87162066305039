.nav-hamburger {
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    height: 40px;
    width: 40px;
    border: 2px solid var(--super-light-blue);
    color: var(--super-light-blue);
    border-radius: 5px;
}

.nav-hamburger.open .hamburger-icon,
.nav-hamburger.closed .close-icon {
    display: none;
}

.nav-hamburger.closed .hamburger-icon,
.nav-hamburger.open .close-icon {
    display: block;
}

@media only screen and (max-width: 600px) {
    .nav-hamburger {
        display: flex;
    }
}