@use '../variables';

.button {
    $button-padding-x: 20px;
    $button-padding-y: 5px;

    display: flex;
    align-items: center;
    padding: $button-padding-y $button-padding-x;
    box-sizing: border-box;
    min-width: 100px;
    width: fit-content;
    height: 50px;
    cursor: pointer;
    border: none;
    user-select: none;

    &.jn-bg-pop-blue:active {
        background-color: variables.$dark-blue;
    }
}

a.button, a.button:visited {
    color: variables.$text-color;
}