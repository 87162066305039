@use '../variables';

.header {
    display: flex;
    justify-content: center;

    &>.jn-container {
        width: 100%;
        max-width: 1400px;
        display: flex;
        align-items: center;
        position: sticky;
        height: variables.$header-height;
        top: 0;
    }

    .logo img {
        max-height: calc(variables.$header-height - 20px);
    }

    h1 a,
    h1 a:active {
        color: variables.$text-color;
        text-decoration: none;
    }
}

@media only screen and (max-width: 1800px) {
    .header>.jn-container {
        max-width: 1600px;
    }
}

@media only screen and (max-width: 1400px) {
    .header>.jn-container {
        max-width: 1000px;
    }
}

@media only screen and (max-width: 1200px) {
    .header>.jn-container {
        max-width: 900px;
    }
}

@media only screen and (max-width: 900px) {
    .header>.jn-container {
        max-width: unset;
        padding: 0 40px;
        justify-content: space-between;
    }
}