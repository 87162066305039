.footer {
    padding: 100px 0 50px 0;

    &>.jn-container {
        width: 1300px;
        max-width: 1300px;
    }
}

@media only screen and (max-width: 1800px) {
    .footer>.jn-container {
        width: 1000px;
        max-width: 1000px;
    }
}

@media only screen and (max-width: 1400px) {
    .footer>.jn-container {
        width: 800px;
        max-width: 800px;
    }
}

@media only screen and (max-width: 1000px) {
    .footer>.jn-container {
        width: 550px;
        max-width: 550px;
    }
}

@media only screen and (max-width: 600px) {
    .footer>.jn-container {
        width: 350px;
        max-width: 350px;
    }
}

@media only screen and (max-width: 400px) {
    .footer>.jn-container {
        width: 300px;
        max-width: 300px;
    }
}