@use '../variables';

.quarter-card,
.half-card,
.full-card {
    padding: 20px;
    border: 2px solid variables.$super-light-blue;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    h2 {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }

    .card-body {
        flex: 2;
    }
}

.quarter-card {
    max-width: calc(25% - variables.$gap-width);
}

.half-card {
    max-width: calc(50% - variables.$gap-width);
}

.full-card {
    max-width: 100%;
}

/* Edge Cards */

.right-edge-card,
.left-edge-card {
    border: 2px solid variables.$super-light-blue;
    height: fit-content;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 95%;

    img {
        max-width: 150px;
        height:auto;
    }
}

.right-edge-card {
    border-right: none;
    border-radius: 20px 0 0 20px;
}

.left-edge-card {
    border-left: none;
    border-radius: 0 20px 20px 0;
}

/* sm card */
@media only screen and (min-width: 576px) {
    .quarter-card-sm {
        max-width: calc(25% - variables.$gap-width);
    }

    .half-card-sm {
        max-width: calc(50% - variables.$gap-width);
    }

    .full-card-sm {
        max-width: 100%;
    }

    .left-edge-card,
    .right-edge-card {
        img {
            max-width: 200px;
            height:auto;
        }
    }
}

/* md card */
@media only screen and (min-width: 768px) {
    .quarter-card-md {
        max-width: calc(25% - variables.$gap-width);
    }

    .half-card-md {
        max-width: calc(50% - variables.$gap-width);
    }

    .full-card-md {
        max-width: 100%;
    }

    .left-edge-card,
    .right-edge-card {
        img {
            max-width: 300px;
            height:auto;
        }
    }
}

/* lg card */
@media only screen and (min-width: 992px) {
    .quarter-card-lg {
        max-width: calc(25% - variables.$gap-width);
    }

    .half-card-lg {
        max-width: calc(50% - variables.$gap-width);
    }

    .full-card-lg {
        max-width: 100%;
    }
}

/* xl card */
@media only screen and (min-width: 1200px) {
    .quarter-card-xl {
        max-width: calc(25% - variables.$gap-width);
    }

    .half-card-xl {
        max-width: calc(50% - variables.$gap-width);
    }

    .full-card-xl {
        max-width: 100%;
    }

    .left-edge-card, .right-edge-card {
        width: 80%;
    }
}

/* xxl card */
@media only screen and (min-width: 1400px) {
    .quarter-card-sm {
        max-width: calc(25% - variables.$gap-width);
    }

    .half-card-sm {
        max-width: calc(50% - variables.$gap-width);
    }

    .full-card-sm {
        max-width: 100%;
    }
}