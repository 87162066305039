/* slide in */

@keyframes slide-from-right {
    from {
        transform: translateX(80%);
    }
    to {
        transform: translateX(0%);
    }
}

@keyframes fade-in {
    from {
        transform: translateX(100px);
        transform: translateY(600px);
        opacity: 0;
    } to {
        transform: translateX(0);
        transform: translateY(0);
        opacity: 100%;
    }
}

.jn-anim-slide-from-right {
    animation-duration: 1.5s;
    animation-name: slide-from-right;
}

.jn-opacity-0 {
    opacity: 0;
}

.jn-opacity-100 {
    opacity: 100% !important;
}

.jn-anim-fade-in {
    transition: opacity 1.5s;
}