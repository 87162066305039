.header {
    display: flex;
    justify-content: center;
}

.header > .jn-container {
    width: 100%;
    max-width: 1400px;
    display: flex;
    align-items: center;
    background-color: var(--not-quite-black);
    position: sticky;
    height: var(--header-height);
    top: 0;
}

.header .logo img {
    max-height: calc(var(--header-height) - 20px);
}

.header h1 a,
.header h1 a:active {
    color: var(--text-color);
    text-decoration: none;
}

@media only screen and (max-width: 1800px) {
    .header > .jn-container {
        max-width: 1600px;
    }
}

@media only screen and (max-width: 1400px) {
    .header > .jn-container {
        max-width: 1000px;
    }
}

@media only screen and (max-width: 1200px) {
    .header > .jn-container {
        max-width: 900px;
    }
}

@media only screen and (max-width: 900px) {
    .header > .jn-container {
        max-width: unset;
        padding: 0 40px;
        justify-content: space-between;
    }
}
