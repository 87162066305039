@media only screen and (max-width: 767px) {
    .dynamic-image-container {
        width: 100%;
        height: 150px;
    }
}

.dynamic-image-content {
    flex: 1 0;
}

@media only screen and (min-width: 768px) {
    .dynamic-image-container {
        width: 25%;
    }
}